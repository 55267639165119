/**
 * Created by alexis on 18/05/17.
 */
activeWindow = null

function nl2br(str, is_xhtml) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

var MapActivity = function (google) {
    this.google = google;
    this.map = null;
    this.originLatLng = null;
    this.markers = null;
    this.infowindow = null;
};

MapActivity.prototype.setOrigin = function (lat, lng) {
    this.originLatLng = new this.google.maps.LatLng(lat, lng);
};

MapActivity.prototype.getOrigin = function () {
    return this.originLatLng;
};

MapActivity.prototype.addOriginWithMarker = function (lat, lng) {
    this.setOrigin(lat, lng);
    return this.addMarker(lat, lng, 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png');
};

MapActivity.prototype.createMap = function (lat, lng) {
    this.map = new this.google.maps.Map(document.getElementById('activity-map'), {
        zoom: 12,
        center: {lat: parseFloat(lat), lng: parseFloat(lng)},
        scrollwheel: false
    });

    return this;
};

MapActivity.prototype.panToOffset = function (latlng, offsetX, offsetY) {
    var self = this;
    var map = self.map;

    var ov = new google.maps.OverlayView();

    ov.onAdd = function () {
        var proj = this.getProjection();
        var aPoint = proj.fromLatLngToContainerPixel(latlng);
        aPoint.x = aPoint.x + offsetX;
        aPoint.y = aPoint.y + offsetY;
        map.panTo(proj.fromContainerPixelToLatLng(aPoint));
    };
    ov.draw = function () {
    };
    ov.setMap(map);
};

MapActivity.prototype.getMap = function () {
    return this.map;
};

MapActivity.prototype.addMarkerFromMapActivity = function (json) {
    return this.addMarker(json.latitude, json.longitude, json.thumbnail);
};

MapActivity.prototype.addMarker = function (lat, lng, icon) {
    return new this.google.maps.Marker({
        position: {lat: parseFloat(lat), lng: parseFloat(lng)},
        map: this.map,
        icon: icon
    });
};

MapActivity.prototype.markerAddInfoWindow = function (marker, content) {
    var self = this;
    var map = self.map;

    var iWindow = new google.maps.InfoWindow({
        content: content,
        maxWidth: 500
    });

    marker.addListener('click', function () {

        if (activeWindow != null) activeWindow.close();

        iWindow.open(map, marker);
        self.panToOffset(marker.position, 0, -150);

        //Store new window in global variable
        activeWindow = iWindow;
    });
};


MapActivity.formatContentForInfoWindow = function (content) {
    var formatted = '<div style="position:relative;">';

    if (typeof content.title !== 'undefined' && content.title != null) {
        formatted += '<p>';
        formatted += '<strong>' + content.title + '</strong>'
        formatted += '</p>';
    }

    if (typeof content.matrix === 'object') {

        formatted += '<div style="color:#1ebfc8">';

        if (typeof content.matrix.distance === 'object') {
            formatted += '<span>' + content.matrix.distance.text + '</span>';
        }

        if (typeof content.matrix.duration === 'object') {
            formatted += '<span style="display:inline-block;margin-left:4px;">(' + content.matrix.duration.text + ')</span>';
        }

        formatted += '</div>';

    }


    if (typeof content.description !== 'undefined' && content.description != null) {
        formatted += '<div style="padding-top:5px;"><em>' + nl2br(content.description) + '</em></div>';
    }

    if (typeof content.title !== 'undefined' && content.link != null) {
        formatted += '<div style="padding-top:5px;"><a href="' + content.link + '" style="color:#1ebfc8" target="_blank">' + content.link + '</a></div>';
    }

    formatted += '</div>';

    return formatted;
};

MapActivity.prototype.getDistanceMatrix = function (destination, callback) {
    var self = this;
    var service = new this.google.maps.DistanceMatrixService();
    var destinationLatLng = new this.google.maps.LatLng(destination.lat, destination.lng);

    service.getDistanceMatrix(
        {
            origins: [self.getOrigin()],
            destinations: [destinationLatLng],
            travelMode: 'DRIVING',
            unitSystem: this.google.maps.UnitSystem.METRIC,
            durationInTraffic: true,
            avoidHighways: false,
            avoidTolls: false
        },
        response_data
    );

    function response_data(responseDis, status) {
        if (status !== this.google.maps.DistanceMatrixStatus.OK) {
            callback(false);
        } else {
            callback(responseDis.rows[0].elements[0]);
        }
    }
};

MapActivity.prototype.addDisplayableMarker = function (activity) {
    var self = this;
    var marker = self.addMarkerFromMapActivity(activity);

    self.getDistanceMatrix({lat: activity.latitude, lng: activity.longitude}, function (response) {
        activity.matrix = null;
        if (response !== false) {
            activity.matrix = response;
        }

        self.markerAddInfoWindow(marker, MapActivity.formatContentForInfoWindow(activity));
    });

    return marker;
};

MapActivity.prototype.displayMarkerActivitiesByActivityMapDuration = function (activities, duration, position) {
    var bounds = new google.maps.LatLngBounds();

    for (var m in this.markers) {
        this.markers[m].setMap(null);
    }

    this.markers = [];

    for (var i in activities) {
        var activity = null;

        if (duration == 0 || activities[i].duration <= duration) {
            activity = activities[i];
        }

        if (activity !== null) {

            var marker = this.addDisplayableMarker(activity);

            // Add id_activity var to marker object : for binding click on activity item in listing
            marker.id_activity = activity.id_activity;

            this.markers.push(marker);
            loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
            bounds.extend(loc);
        }
    }


    // Add Calais or Dover marker for centering
    loc = new google.maps.LatLng(position.lat, position.lng);
    bounds.extend(loc);

    this.map.fitBounds(bounds);
    this.map.panToBounds(bounds);


    var currentMarkers = this.markers;

    // Add event listener when clicking on activity item
    $('.activity-item a').on('click', function (e) {
        e.preventDefault();


        google.maps.event.trigger(currentMarkers[$(this).data('marker-id')], 'click');

        if (currentWindowWidth <= smartWidthMax) {
            $.scrollTo($('#activity-map'), 350)
        }
    })

};

(function ($) {
    $(function () {
        if ($('#activity-map').length) {
            GoogleMapsLoader.load(function (google) {
                var CALAIS_POSITION = {
                    lat: 50.953286,
                    lng: 1.860373
                };

                var DOUVRE_POSITION = {
                    lat: 51.128760,
                    lng: 1.313679
                };

                $.get(basePath + '/json/map-activity?language=' + getLocale(), function (activities) {
                    var mapActivity = new MapActivity(google);
                    var position = null;

                    switch (getLocale()) {
                        case 'en':
                            position = CALAIS_POSITION;
                            break;
                        case 'fr':
                            position = DOUVRE_POSITION;
                            break;
                        default:
                            position = CALAIS_POSITION;
                    }


                    mapActivity.createMap(position.lat, position.lng);
                    mapActivity.addOriginWithMarker(position.lat, position.lng);
                    mapActivity.displayMarkerActivitiesByActivityMapDuration(activities, 1, position);


                    $('select[name="map_activity_duration"]').on('change', function () {
                        var valu = $(this).val()


                        // Iteractivity with maps
                        mapActivity.displayMarkerActivitiesByActivityMapDuration(activities, valu, position);

                        // Show activities in list
                        switch (valu) {
                            case '1':
                                $('.activity-item').hide()
                                $('.activity-item.duration-1').show()
                                break;
                            case '2':
                                $('.activity-item').hide()
                                $('.activity-item.duration-1, .activity-item.duration-2 ').show()
                                break;
                            case '3':
                                $('.activity-item').hide()
                                $('.activity-item.duration-1, .activity-item.duration-2, .activity-item.duration-3 ').show()
                                break;
                            case '4':
                                $('.activity-item').hide()
                                $('.activity-item.duration-4 ').show()
                                break;
                            default:
                                $('.activity-item').show()
                        }


                        if ($('.activity-item:visible').length > 0) {
                            $('.form-list-activities .alert-warning').hide();
                        } else {
                            $('.form-list-activities .alert-warning').show();
                        }

                    }).trigger('change');


                });
            });
        }
    });
})(jQuery);

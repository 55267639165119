(function($) {

    $.fn.fillForm = function (json) {
        var form = $(this);

        $.each(json, function (i, val) {
            var input = form.find('[name="'+i+'"]');

            switch (input.attr('type')) {
                case 'checkbox':
                case 'radio':
                    $.each(input, function (j, checkbox) {
                        var $checkbox = $(checkbox);

                        if (!val instanceof Array) {
                            val = [val];
                        }

                        for (var n in val) {
                            if ($checkbox.val() == val[n]) {
                                $checkbox.attr('checked', 'checked');
                            }
                        }
                    });
                    break;
                case 'textarea':
                    input.text(val);
                    break;
                default:
                    input.val(val);
                    break;
            }
        });
    };

    $.fn.resetForm = function (json) {
        var form = $(this);

        $.each(json, function (i, val) {
            var input = form.find('[name="'+i+'"]');

            switch (input.attr('type')) {
                case 'checkbox':
                case 'radio':
                    $.each(input, function (j, checkbox) {
                        var $checkbox = $(checkbox);

                        if (!val instanceof Array) {
                            val = [val];
                        }

                        for (var n in val) {
                            if ($checkbox.val() == val[n]) {
                                $checkbox.removeAttr('checked');
                            }
                        }
                    });
                    break;
                case 'select':
                    input.val(input.parent().find('option:first').val());
                    break;
                case 'textarea':
                    input.text('');
                    break;
                default:
                    input.val('');
                    break;
            }
        });
    };

    $(function () {
        var
            context = $('body.rental-listing'),
            resultsContainer = $('.contain-results'),
            rentalLoader = $('<div class="flexLoader"><div class="spinner"></div></div>');

        // Rewrite pagination with updated query search
        var rewritePagination = function () {
            $(context).find('.contain-results .paginate a').each(function (el) {
                $(this).attr('href', $(this).attr('href') + window.location.search);
            });
        };

        var displayNbResults = function () {
            $(context).find('.nb-results span').addClass('cc-hidden');

            var nbResults = $(context).find('.contain-results [data-nb-results]').data('nb-results');
            switch (parseInt(nbResults)) {
                case 0:
                    $(context).find('.nb-results span.results-any').removeClass('cc-hidden');
                    break;
                case 1:
                    $(context).find('.nb-results span.results-one').removeClass('cc-hidden');
                    break;
                default:
                    $(context).find('.nb-results span.results-more').removeClass('cc-hidden');
                    $(context).find('.nb-results span.results-more i').text(nbResults);
                    break;
            }
        };

        var manageTypeFilter = function () {
            var types = $('input[name="type[]"]');
            var groupA = context.find('.filters-group-a');
            var groupB = context.find('.filters-group-b');

            groupA.addClass('cc-hidden');
            groupB.addClass('cc-hidden');

            types.each(function () {
                if ($(this).prop('checked')) {
                    console.log($(this).val());
                    if ($(this).val() == 4) {
                        groupB.removeClass('cc-hidden');
                    } else {
                        groupA.removeClass('cc-hidden');
                    }
                 }
            });
        };

        if (context.length) {
            var xhr = null;
            var currentBaseUrl = window.location.origin+window.location.pathname;

            displayNbResults();
            $('.filters form').fillForm(queryToJson(location.search));
            rewritePagination();
            manageTypeFilter();

            // Change filter
            context.on('change', '.filters form', function () {
                var serialized = $(this).serialize();
                var url = currentBaseUrl;

                // If filter change we need to redirect to first page of results
                if (url.split('/').length === 7) {
                      currentBaseUrl = url = url.substr(0, url.lastIndexOf('/') + 1) + '1';
                }

                url = url+'?'+serialized;

                rentalLoader.appendTo(resultsContainer);

                // Change current URL without reloading to share link with filters
                window.history.pushState("", "", url);

                if (xhr !== null) {
                    xhr.abort();
                }

                xhr = $.ajax({
                   url: currentBaseUrl,
                   data: serialized,
                   method: 'POST'
                })
                .done(function (data) {
                    $(context).find('.contain-results').html(data);
                    displayNbResults();
                    rewritePagination();
                })
                .fail(function () {
                    $(context).find('.contain-results').html('<div class="alert alert-error" data-nb-results="0">Aucun résultat. Merci de modifier vos filtres.</div>');
                    displayNbResults();
                })
                .always(function(){
                    rentalLoader.fadeOut('fast',function(){
                        $(this).remove();
                    });
                });

                manageTypeFilter();
            });

            // Clear filters
            context.find('a.clear-filters').on('click', function (e) {
                e.preventDefault();

                $('.filters form').resetForm(queryToJson(location.search))
                $('.filters form').trigger('change');

                window.history.pushState("", "", currentBaseUrl);
            });
        }
    });
})(jQuery);

function getLocale() {
    var locale = window.location.pathname.replace('/', '').split('/', 1);

    if (locale.length) {
        locale = locale[0];
    } else {
        locale = 'fr';
    }

    return locale;
}

function queryToJson(url) {
    var json = {};

    if (url.indexOf('?') !== -1) {
        url = url.slice(url.indexOf('?') + 1);
    }

    var hashes = url.split('&');

    for (var i in hashes) {
        var splitted = hashes[i].split('=');
        var key = decodeURIComponent(splitted[0]);

        // Can be an array if not set to null
        if (typeof json[key] === 'undefined') {
            json[key] = null;
        }

        if (splitted.length === 2) {
            var value = decodeURIComponent(splitted[1]);

            if (key.substr(-2) === '[]') {
                if (json[key] === null) {
                    json[key] = [];
                }

                json[key].push(value);
            } else {
                json[key] = value;
            }
        }
    }

    return json;
}

(function ($) {
    GoogleMapsLoader.KEY = 'AIzaSyDcTsEYuChkpkRzTO_MBbRAiydAsTK_mfY';
    GoogleMapsLoader.VERSION = '3.35';

    /* @ global vars */
    smartWidthMin = 0;
    smartWidthMax = 767;

    tablWidthMin = 768;
    tablWidthMax = 1300;

    windowWidthMin = 1301;
    currentWindowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    currentWindowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    /*
     @name : setCookie, getCookie, checkCookie
     @function : check, get et set a cookie
     */
    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + "; " + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1);
            if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
        }
        return "";
    }

    function checkCookie(cname) {
        var user = getCookie(cname);
        return (user != "") ? true : false;
    }


    /*
     OUTDATEDBROWSER : inspired by http://outdatedbrowser.com/fr
     @function : check id browser support css3 transitions. If yes, return true, if not, return false (so display #outdated div)
     @return : true or false
     */
    // check if transition is in the browsert CSSStyleDeclaration
    $.support.transition = (function () {
        var thisBody = document.body || document.documentElement;
        var thisStyle = thisBody.style;
        var support = thisStyle.transition !== undefined || thisStyle.WebkitTransition !== undefined || thisStyle.MozTransition !== undefined || thisStyle.MsTransition !== undefined || thisStyle.OTransition !== undefined;
        return support;
    })();


    $(function () {
        /*
         -- @ OUTDATEDBROWSER @ --
         */
        if (!$.support.transition) {
            var htmlOutDated = '<h6>Ce navigateur internet est dépassé !</h6><p>Mettez à jour votre navigateur internet pour bénéficier de toutes les fonctionnalités du site.<a class="btnUpdateBrowser" href="http://outdatedbrowser.com/">Mettre à jour maintenant</a></p>';
            $('#outdated').html(htmlOutDated).addClass('shown');
        } else {
            $('#outdated').hide();
        }

        /*
         -- @ FASTCLICK @ --
         * Init FastClick on phone devices
         */
        if (currentWindowWidth <= smartWidthMax) {
            window.addEventListener('load', function () {
                new FastClick(document.body);
            }, false);
        }

        /*
         -- @ COOKIES @ --
         * Show cookies block if ck cookie does not exist
         */
        var user = checkCookie('_ck');
        if (!user)
            $('#cookies').show();

        $('#cookies a').on('click', function (e) {
            var isCookify = checkCookie('_ck');
            if (!isCookify)
                setCookie('_ck', 'b326b5062b2f0e69046810717534cb09', '365');

            if ($(this).hasClass('close')) {
                e.preventDefault();
                $(this).parent().slideUp('fast');
            }
        });

        // Use cookie for font size
        $('.flags-fonts .fonts a').each(function (i, el) {
            $(this).on('click', function (e) {
                e.preventDefault();

                var
                    isFontify = checkCookie('_fs'),
                    _this = $(this);

                // Si le cookie n'existe pas, on le créé
                if (!isFontify)
                    setCookie('_fs', 1.6, '365');

                var fontSize = parseFloat(getCookie('_fs'));

                // Descrease font size
                if (_this.hasClass('minus') && fontSize > 1.4) {
                    var newFontSize = fontSize - 0.1;
                    setCookie('_fs', newFontSize.toFixed(1), '365');
                }
                // Increase font size
                if (_this.hasClass('plus') && fontSize < 1.9) {
                    var newFontSize = fontSize + 0.1;
                    setCookie('_fs', newFontSize.toFixed(1), '365');
                }

                /* Change body class */
                var klass = parseFloat(getCookie('_fs'));

                $('body').removeClass(function (index, css) {
                    return (css.match(/\bfont-\S+/g) || []).join(' ');
                }).addClass('font-' + klass * 10);
            });
        });


        /*
         -- @ LINKS @ --
        */
        $('a[href$=".pdf"],a.external-link').on('click', function (e) {
            e.preventDefault();
            window.open($(this).attr("href"));
        });
        $('a.backToTop').on('click', function (e) {
            e.preventDefault();
            $('body,html').animate({scrollTop: 0}, 250, 'swing');
        });
        $('a[href="#"], a[href="GOTOLINK"]').on('click', function (e) {
            e.preventDefault();
        });

        /*
         -- @ BOOTSTRAP @ --
         */
        // Classical tooltips
        if ($('[data-toggle="tooltip"]').length)
            $('[data-toggle="tooltip"]').tooltip();

        if ($('[data-toggle="tooltip-error"]').length) {
            $('[data-toggle="tooltip-error"]').tooltip({
                template: '<div class="tooltip error" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
            });
        }

        /*
         -- @ FLEXSLIDER @ --
        */
        if ($('.flexslider').length) {

            $('.flexslider').each(function (i, el) {
                var
                    animation = 'fade',
                    showArrows = false,
                    showControlNav = true;

                // Specific for last news slider
                if ($(this).hasClass('flex-news')) {
                    var
                        animation = 'slide'
                        showArrows = true,
                        showControlNav = false;
                }

                $(this).flexslider({
                    animation: animation,
                    animationLoop: true,
                    directionNav: showArrows,
                    prevText: '<i class="ion-ios-arrow-back"></i>',
                    nextText: '<i class="ion-ios-arrow-forward"></i>',
                    controlNav: showControlNav,
                    slideshow: true,
                    slideShowSpeed: 5000,
                    pauseOnAction: false,
                    start: function (slider) {
                        slider.find('.flexLoader').fadeOut('fast', function () {
                            $(this).remove();
                        });
                    }
                });
            });
        }

        /*
         -- @ BACKSTRETCH @ --
         * Attach responsive background-images to elements
         */
        if ($('.backstretch').length) {
            $('.backstretch').each(function (i, el) {
                var imgName = $(this).attr('data-img');

                if (imgName != '' || imgName !== 'undefined')
                    $(this).backstretch(imgName);
            });
        }


        /*
         -- @ BODY FIELDS @ --
         * Add clearfix class for parent containing images on wywiwyg page
         */
        $('.body p, .body div').has('img.right, img.left').addClass('clearfix');
        $('.body p, .body div').find('a[href$=".jpg"], a[href$=".png"], a[href$=".jpeg"]').on('click', function (e) {
            e.preventDefault();
            window.open($(this).attr("href"));
        });


        /*
         -- @ FAQ @ --
         * Show answer on question click
         */
        $('.faq .question').each(function (i, el) {
            $(this).find('a').on('click', function (e) {
                var
                    question = $(this).parent();
                answer = question.next();

                if (answer.is(':hidden')) {
                    answer.stop(true, true).slideDown('fast');
                }
                else {
                    answer.stop(true, true).slideUp('fast');
                }
            });
        });

        /*
         -- @ Modal on Homepage @ --
         */
        // Alertes retards intempéries
        if ($('body.modalized').length) {
            setTimeout(function () {
                $('#modal-weather').addClass('active')
            }, 2000);
        }

        // Modal marine trafic
        $('.modal-marine-traffic-launcher').on('click', function (e) {
            e.preventDefault();
            var btn = $(this);

            btn.addClass('cc-loading');

            var modal = $(document.getElementById($(this).data('target')));

            $.get($(this).attr('href'))
                .done(function(data){
                    modal.find('.modal-body').html(data);
                    btn.removeClass('cc-loading');
                    $('body').addClass('opened-modal');
                    modal.addClass('active');
                })
                .fail(function(){
                    btn.removeClass('cc-loading');
                });
        });

        $('.modal-open').on('click', function (e) {
            e.preventDefault();

            var modal = $(this).data('target');
            var modalElement = $(document.getElementById(modal));

            if (modalElement.length) {
                $('body').addClass('opened-modal');
                modalElement.addClass('active');
            } else {
                alert('ChuckCSS error : modal with attribute id="' + modal + '" is not defined!');
            }
        });

        /* Close modal */
        $('.modal:not([data-disabled-overlay])')
            .find('.modal-overlay')
            .add('.modal .modal-close')
            .on('click', function (e) {

                if ($(this).parent().hasClass('active'))
                    $(this).parent().removeClass('active');

                if (!$('.modal.active').length)
                    $('body').removeClass('opened-modal');
            });


        /*
         -- @ Menu Univers on Tablets & Phones @ --
         */
        if ($('#main-header nav').length) {
            var
                menu = $('#main-header nav'),
                subMenu = $('#sub-menu');

            // Univers menu
            $('.tablet_menu').on('click', function (e) {
                e.preventDefault();

                menu.addClass('shown');
                $('body').addClass('opened-menu');

                // Si on est sur une page d'univers, on ouvre directement le sous-menu
                if(menu.find('.open-sub-menu').length) {
                    menu.find('a.active .open-sub-menu').trigger('click');
                }

            });
            menu.find('.close-menu').on('click', function (e) {
                console.log('click close-menu');

                e.preventDefault();
                menu.removeClass('shown');
                $('body').removeClass('opened-menu');
            });


            // Sub-menus
            menu.find('a.active .open-sub-menu').on('click', function (e) {

                console.log('click open-sub-menu');

                e.preventDefault();
                subMenu.addClass('shown');
                menu.removeClass('shown');

            });
            subMenu.find('a.close-sub-menu').on('click', function (e) {

                console.log('click close-sub-menu');

                e.preventDefault();
                subMenu.removeClass('shown');
                menu.addClass('shown');
            });
        }

        // Disabled click on sub-menu with children
        if($('#sub-menu').length) {
            $('#sub-menu .sub-menu').find('a').on('click',function(e){
                if($(this).next().is('ul')) {
                    e.preventDefault();
                }
            });
        }


        // Search form on Header
        if($('#main-header').length) {
            var
                mainHeader = $('#main-header'),
                form = $('form[name="app_cms_search_type"]'),
                input = form.find('input#app_cms_search_type_research'),
                btnClose = $('a.close-search-form');

            // Submit form : check if value is not empty && add 'open' class to form
            form.on('submit',function(e){
                var value = $.trim(input.val());

                // Si form non déployé, on déploie mais on ne submit pas
                if(!mainHeader.hasClass('form-open') || value == '') {
                    e.preventDefault();
                    $('#main-header, #main-header nav, .header-features').addClass('form-open');
                }
            });

            // Phone : close search form
            btnClose.on('click',function(e){
                e.preventDefault();
                $('#main-header').removeClass('form-open');

                // Add a small timeout for anmation bug fixing
                setTimeout(function(){
                    $('#main-header nav, .header-features').removeClass('form-open');
                },250);
            });
        }

        // WEATHER INFOS on HOMEPAGE
        if($('.header-weather').length) {
            var
                calais = $('.weather-calais'),
                boulogne = $('.weather-boulogne'),
                CALAIS_API = basePath+"/weather/calais",
                BOULOGNE_API = basePath+"/weather/boulogne",
                icons = {
                    '01d' : 'wi-day-sunny', // Day : clear sky
                    '02d' : 'wi-day-cloudy', // Day : few clouds
                    '03d' : 'wi-cloud', // Day : scattered clouds
                    '04d' : 'wi-cloudy', // Day : broken clouds
                    '09d' : 'wi-rain', // Day : shower rain
                    '10d' : 'wi-day-hail', // Day : rain
                    '11d' : 'wi-storm-showers', // Day : thunderstorm
                    '13d' : 'wi-snow', // Day : snow
                    '50d' : 'wi-fog', // Day : mist
                    '01n' : 'wi-night-clear', // Night : clear sky
                    '02n' : 'wi-night-alt-cloudy', // Night : few clouds
                    '03n' : 'wi-cloud', // Night : scattered clouds
                    '04n' : 'wi-cloudy', // Night : broken clouds
                    '09n' : 'wi-rain', // Night : shower rain
                    '10n' : 'wi-night-alt-hail', // Night : rain
                    '11n' : 'wi-storm-showers', // Night : thunderstorm
                    '13n' : 'wi-snow', // Night : snow
                    '50n' : 'wi-night-fog' // Night : mist
                };

            // Calais weather
            $.get(CALAIS_API)
                .done(function(data) {
                    if (jQuery.isEmptyObject(data)) {
                        calais.hide();
                        return;
                    }

                    var
                        main_icon = data.weather[0].icon,
                        temp = data.main.temp;


                    // Fill temperature
                    calais.find('.temp').text(temp.toFixed(0))

                    // Fill icon weather
                    if(main_icon in icons)
                        calais.find('.main-icon').addClass(icons[main_icon])
                    else
                        calais.find('.main-icon').remove();

                    calais.addClass('shown');

                })
                .fail(function(){
                    calais.hide();
                });

            // Boulogne weather
            $.get(BOULOGNE_API)
                .done(function(data) {
                    if (jQuery.isEmptyObject(data)) {
                        boulogne.hide();
                        return;
                    }

                    var
                        main_icon = data.weather[0].icon,
                        temp = data.main.temp;


                    // Fill temperature
                    boulogne.find('.temp').text(temp.toFixed(0))

                    // Fill icon weather
                    if(main_icon in icons)
                        boulogne.find('.main-icon').addClass(icons[main_icon])
                    else
                        boulogne.find('.main-icon').remove();

                    boulogne.addClass('shown');
                })
                .fail(function(){
                    boulogne.hide();
                });
        }


        // Hide Weather on scroll and resize fixed Header
        $(window).on('scroll',function(e){
            if ($(window).scrollTop() > 200) {

                if($('.header-weather').length) {
                    $('.header-weather').addClass('hidden');
                }

                $('#sub-menu,#main-header').addClass('thin');

            } else {

                if($('.header-weather').length) {
                    $('.header-weather').removeClass('hidden');
                }

                $('#sub-menu,#main-header').removeClass('thin');

            }
        })

        // PROCHAINS DEPARTS
        if($('.next-departures').length) {

            // Return formated hour with a '0' for numbers less than 10
            var formatHour = function(hour) {
                return ('0' + hour).slice(-2)
            };

            // Apply 'shown' class to ranged <tr>, and remove 'shown' class to entire table
            var showTr = function(toShow, toHide) {
                toHide.removeClass('shown');
                toShow.addClass('shown');
            };

            // Return slices datas to apply 'shown' class after
            var sliceDatas = function(datas, limit) {
                return datas.slice(0,limit);
            };

            var
                nbToShow = 3,
                bloc = $('.next-departures'),
                tableDatas = bloc.find('table'),
                btnSeeMore = bloc.find('.js-see-more'),
                selectList = bloc.find('select'),
                selectedHour = formatHour(bloc.find('option:selected').val());


            var trNext = tableDatas.find('tr.h-'+selectedHour).nextAll().andSelf();

            // Get three <TR> to show (when page is loaded)
            var trShown = sliceDatas(trNext, nbToShow);

            // Show tr
            showTr(trShown, tableDatas);

            // Btn : "Départs suivants"
            btnSeeMore.on('click',function() {

                // If tr.shown exists
                if(tableDatas.find('tr.shown').length) {
                    var newTrToShow = sliceDatas(tableDatas.find('tr.shown:last').nextAll(), nbToShow);
                } else {
                    var newTrToShow = trShown
                }

                if(newTrToShow.length > 0) {
                    // Show new <tr>
                    showTr(newTrToShow, tableDatas.find('tr'));
                }

            });

            // Change selected hour
            selectList.on('change',function(e){
                var
                    newHour = formatHour(selectList.find('option:selected').val()),
                    trNext = tableDatas.find('tr.h-'+newHour).nextAll().andSelf(),
                    nbResult = trNext.length;

                // If no results, pass to next hour
                if(nbResult == 0) {
                    for(var i = parseInt(newHour); i < 25; i++) {
                        var incrementHour = i+1;
                        var trNext = tableDatas.find('tr.h-'+incrementHour).nextAll().andSelf();

                        if(trNext.length > 0)
                            break;
                    }
                }

                // Hide all <TR> before
                var newTrToShow = sliceDatas(trNext, nbToShow);
                showTr(newTrToShow, tableDatas.find('tr'));

            });
        }

        // PHONE : Show footer sitemap on click "Show sitemap"
        $('.show-sitemap a').on('click',function(e){
            e.preventDefault();

            if($('.wrap-footer-menu').find('> ul').hasClass('shown')) {
                $('.wrap-footer-menu').find('> ul').removeClass('shown')
            } else {
                $('.wrap-footer-menu').find('> ul').addClass('shown')
                $.scrollTo($('.show-sitemap a'), 300, {axis:'y'})
            }

        })



        // Accordeons
        $('.accordeon-title').on('click', function (e) {
            e.preventDefault();

            var _title = $(this);

            if (_title.hasClass('active')) {
                _title.removeClass('active');
                _title.next().stop(true, false).slideUp('fast');
            } else {
                _title.addClass('active');
                _title.next().stop(true, false).slideDown('fast');
            }
        });

        // HASH IN CMS PAGE, ACCORDING TO THE URL WITH ANCHOR LNK
        // Demande de Gwendo du 30 juin 2017. Si url contient une ancre, on ouvre l'accordéon appropriée
        /*
            En FR :
            - https://www.portboulognecalais.fr/fr/preparer-votre-traversee-calais-douvres#parkings
            - https://www.portboulognecalais.fr/fr/preparer-votre-traversee-calais-douvres#reserver-un-billet
            - https://www.portboulognecalais.fr/fr/preparer-votre-traversee-calais-douvres#formalites-et-controles
            - https://www.portboulognecalais.fr/fr/preparer-votre-traversee-calais-douvres#ce-qu-un-francais-doit-savoir-en-arrivant-au-royaume-uni
            - https://www.portboulognecalais.fr/fr/preparer-votre-traversee-calais-douvres#ou-manger-ou-dormir-autour-de-calais

            Idem en version EN :
            - https://www.portboulognecalais.fr/en/preparing-your-calais-douvres-crossing#car-parks
            - https://www.portboulognecalais.fr/en/preparing-your-calais-douvres-crossing#ticket-bookings
            - https://www.portboulognecalais.fr/en/preparing-your-calais-douvres-crossing#formalities-and-checks
            - https://www.portboulognecalais.fr/en/preparing-your-calais-douvres-crossing#what-every-brit-should-know-before-coming-to-france
            - https://www.portboulognecalais.fr/en/preparing-your-calais-douvres-crossing#dining-and-lodging-near-calais
        */
        if(!Array.prototype.indexOf){
            Array.prototype.indexOf = function(val){
                var i = this.length;
                while (i--) {
                    if (this[i] == val) return i;
                }
                return -1;
            }
        }
        if($('.wrap-accordeons').length && window.location.hash) {
            var
                hashes = {
                    // 'hash without the #': 'Associated accordeon title in page'
                    'parkings': 'Parkings', // FR
                    'reserver-un-billet': 'Réserver un billet', // FR
                    'formalites-et-controles': 'Formalités & Contrôles', // FR
                    'ce-qu-un-francais-doit-savoir-en-arrivant-au-royaume-uni': "Ce qu'un français doit savoir en arrivant au Royaume-Uni", // FR
                    'se-restaurer': 'Se restaurer', // FR

                    'car-parks': 'Car parks', // EN
                    'ticket-bookings': 'Ticket bookings', // EN
                    'formalities-and-checks': 'Formalities and checks', // EN
                    'what-every-brit-should-know-before-coming-to-france': 'What every Brit should know before coming to France', // EN
                    'eating': 'Eating' // EN
                }
                url = window.location.hash,
                finalHash = url.substring(1),
                titlesAccordeons = [];



            // Si le hash correspond à un hash valide
            if (finalHash in hashes) {

                // Get associated title for the current hash
                var titleAccordeon = hashes[finalHash].toLowerCase(); // "parkings" for exemple
                var indexLinktoOpen = 99;

                // Fill an array with accordeons titles of the current page
                $('.wrap-accordeons').find('.accordeon-item').each(function(i,el){
                    var title = $(this).find('a.accordeon-title').text().toLowerCase();

                    // Push title in array
                    titlesAccordeons.push($.trim(title));

                    // If currentTitle == hash title
                    if(titlesAccordeons[i] == titleAccordeon) {
                        indexLinktoOpen = i
                    }
                });

                // if we know the index to open
                if(indexLinktoOpen < 99) {

                    $('.wrap-accordeons').find('.accordeon-item:eq('+indexLinktoOpen+') a').trigger('click');
                    $.scrollTo($('.wrap-accordeons').find('.accordeon-item:eq('+indexLinktoOpen+') a'),350,{offset:-140});

                    // Open Dormir (sleeping) if hash is "Se restaurer" (eating)
                    if(titleAccordeon == 'se restaurer') {
                        var index = titlesAccordeons.indexOf('dormir');
                        console.log(index)
                        $('.wrap-accordeons').find('.accordeon-item:eq('+index+') a').trigger('click')
                    } else if (titleAccordeon == 'eating') {
                        var index = titlesAccordeons.indexOf('sleeping');
                        $('.wrap-accordeons').find('.accordeon-item:eq('+index+') a').trigger('click')
                    }

                } else {
                    console.log('Not able to find title: "'+titleAccordeon+'"')
                }

            } else {
                console.log('hash "' + finalHash + '" is not an valid hash. Available hashes are "parkings", "reserver-un-billet", "formalites-et-controles", "ce-qu-un-francais-doit-savoir-en-arrivant-au-royaume-uni", "se-restaurer", "car-parks", "ticket-bookings", "formalities-and-checks", "what-every-brit-should-know-before-coming-to-france", "eating"');
            }
        }


        // Countdown on Homepage
        if($('#final-countdown-fr').length) {
            $('#final-countdown-fr').countdown("2021/01/13", function(event) {
                $(this).html(
                  // event.strftime(''
                  //   + '<p class="countdown-days black-bg"><span>%D</span><em>%!D:jour,jours;</em></p>'
                  //   + '<p class="countdown-hours black-bg"><span>%H</span><em>%!H:heure,heures;</em></p>'
                  //   + '<p class="countdown-dots"><span>:</span></p>'
                  //   + '<p class="countdown-minutes black-bg"><span>%M</span><em>%!M:minute,minutes;</em></p>')
                  event.strftime('<p class="countdown-days black-bg"><span>%D</span><em>%!D:jour,jours;</em></p>')
                );
            });
        }
        if($('#final-countdown-en').length) {
            $('#final-countdown-en').countdown("2021/01/13", function(event) {
                $(this).html(
                  // event.strftime(''
                  //   + '<p class="countdown-days black-bg"><span>%D</span><em>%!D:day,days;</em></p>'
                  //   + '<p class="countdown-hours black-bg"><span>%H</span><em>%!H:hour,hours;</em></p>'
                  //   + '<p class="countdown-dots"><span>:</span></p>'
                  //   + '<p class="countdown-minutes black-bg"><span>%M</span><em>%!M:minute,minutes;</em></p>')
                  event.strftime('<p class="countdown-days black-bg"><span>%D</span><em>%!D:day,days;</em></p>')
                );
            });
        }
    });
})(jQuery);


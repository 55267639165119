(function($) {

    function initialize(google, id, lng, lat) {
        var mapOptions = {
            zoom: 13,
            center: new google.maps.LatLng(lng,lat),
            scrollwheel:false,
            scaleControl:false,
            streetViewControl:false,
            mapTypeControl:false,
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
            }
        }

        // Snazzy maps colors
        var featureOpts = [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#46bcec"},{"visibility":"on"}]}];

        // Init colorize map
        var customMapType = new google.maps.StyledMapType(featureOpts, {name: 'Custom Style'});

        // Create Google maps
        var map = new google.maps.Map(document.getElementById(id), mapOptions);

        // Apply colors on Maps
        map.mapTypes.set('map_style', customMapType);
        map.setMapTypeId('map_style');

    }

    $(function () {

        if($('#calais-maps').length) {
            GoogleMapsLoader.load(function(google) {
                initialize(google, 'calais-maps', 50.958818, 1.861001);
            });
        }

        if($('#boulogne-maps').length) {
            GoogleMapsLoader.load(function(google) {
                initialize(google, 'boulogne-maps', 50.725008, 1.593964);
            });
        }
    });
})(jQuery);

(function($) {

    function initialize(google, id) {
        var myLatlng = new google.maps.LatLng(50.966843, 1.863514);
        var mapOptions = {
            zoom: 13,
            center: myLatlng,
            scrollwheel:false,
            scaleControl:false,
            streetViewControl:false,
            mapTypeControl:false

        }

        var map = new google.maps.Map(document.getElementById(id), mapOptions);

        var trafficLayer = new google.maps.TrafficLayer();
        trafficLayer.setMap(map);
    }

    $(function () {

        if($('#trafic-maps').length) {
            GoogleMapsLoader.load(function(google) {
                initialize(google, 'trafic-maps');
            });

            /* Show / Hide Trafic maps */
            $('.show-map').on('click',function(e){
                e.preventDefault();
                $(this).toggleClass('active');

                $('.trafic-maps-wrapper').toggleClass('active');
            });
        }

        if($('#trafic-maps-modal').length) {
            GoogleMapsLoader.load(function(google) {
                initialize(google, 'trafic-maps-modal');
            });
        }
    });
})(jQuery);

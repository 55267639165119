(function($) {
    $(function () {
        if($('body.search-results').length) {

            $('.contain-filters a').on('click',function(e){
                e.preventDefault();

                var
                    _this=  $(this),
                    rel = _this.data('filter');

                if(_this.hasClass('clear-filters')) {

                    $('.contain-filters a').removeClass('inactive');

                    $('.contain-results').find('dt,dd').each(function(i,el){
                        $(this).show();
                    });
                }
                else {
                    if(_this.hasClass('inactive')) {
                        if($('.contain-results').find(''+rel+'').length) {
                            _this.removeClass('inactive');

                            $('.contain-results').find(''+rel+'').each(function(i,el){
                                $(this)
                                    .parent().parent()
                                    .add($(this).parent().parent().prev())
                                    .show();
                            });
                        }
                    }
                    else {
                        if($('.contain-results').find(''+rel+'').length) {
                            _this.addClass('inactive');

                            $('.contain-results').find(''+rel+'').each(function(i,el){
                                $(this)
                                    .parent().parent()
                                    .add($(this).parent().parent().prev())
                                    .hide();
                            });
                        }
                    }
                }


                $('.contain-results dt').removeClass('first');
                $('.contain-results dt:visible').eq(0).addClass('first');
            });
        }
    });
})(jQuery);
